<template>
  <main>
    <AuthorizationEmailCode
      text-enter-email="Введи свой email"
      text-enter-code="Введи код из письма, отправленного на почтовый ящик"
    >
      <template #default>
        <h1>Личный кабинет</h1>
        <p>Добро пожаловать на новогоднюю ярмарку «Сбудется», где ты сможешь найти для себя счастье и новые возможности.</p>
        <AuthorizationToken v-show="hasToken">
          <template #auth-error>
            <div class="alert__heading">Ошибка авторизации</div>
            <p>Обращайся в&nbsp;службу новогодней технической поддержки удобным способом:</p>
            <ul>
              <li><a href="tel:0120">0120</a>&nbsp;&mdash; телефон для абонентов МегаФона и&nbsp;<a href="tel:+7 (925) 107-55-59" style="white-space: nowrap">+7 (925) 107-55-59</a> для других операторов.</li>
              <li>в&nbsp;приложение Botman&nbsp;&mdash; создание заявки</li>
              <li>или <a href="https://megahelp.megafon.ru/form/44716" target="_blank">по&nbsp;этой ветке</a> MegaHelp</li>
            </ul>
          </template>
        </AuthorizationToken>
      </template>
      <template #auth-error>
        <div class="alert__heading">Ошибка авторизации</div>
        <p>Обращайся в&nbsp;службу новогодней технической поддержки удобным способом:</p>
        <ul>
          <li><a href="tel:0120">0120</a>&nbsp;&mdash; телефон для абонентов МегаФона и&nbsp;<a href="tel:+7 (925) 107-55-59" style="white-space: nowrap">+7 (925) 107-55-59</a> для других операторов.</li>
          <li>в&nbsp;приложение Botman&nbsp;&mdash; создание заявки</li>
          <li>или <a href="https://megahelp.megafon.ru/form/44716" target="_blank">по&nbsp;этой ветке</a> MegaHelp</li>
        </ul>
      </template>
    </AuthorizationEmailCode>
  </main>
</template>

<script>
import AuthorizationToken from 'base/components/AuthorizationToken'
import AuthorizationEmailCode from 'base/components/AuthorizationEmailCode'

AuthorizationToken.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--auth')

  this.afterMounted()
}

export default {
  components: {
    AuthorizationToken,
    AuthorizationEmailCode
  },
  computed: {
    hasToken () {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get('token') !== null
    }
  }
}
</script>

<style>
.form.form--auth-token {
  padding: 0 !important;
  box-shadow: none !important;
}
</style>

<style lang="css" scoped>
  main {
    margin: auto;
  }

  h1 {
    font-size: 1.8em;
    margin-top: 0;
  }

  .form {
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: var(--border-radius);
    max-width: 520px;
    box-shadow: 0 0 16px rgba(0, 0, 0, .15);
  }

  .step__fields .form-group {
    margin-bottom: 10px;
  }

  .form-field {
    width: 100%;
  }

  .form .button {
    white-space: nowrap;
  }

  @media (min-width: 480px) {
    .step__fields {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }

    .step__fields .form-group {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
</style>
