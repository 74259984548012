<template>
  <form class="form form--auth-token" v-on:hide-errors="hideErrors">
    <slot></slot>
    <div class="alert alert--danger" v-if="error">
      <slot name="auth-error">
        <div v-html="error"></div>
      </slot>
    </div>
  </form>
</template>

<script>
import Vue from 'vue'
import auth from '../api/auth-token'
import appError from 'base/modules/app-error'
import utils from 'base/modules/utils'

export default {
  data () {
    return {
      sending: false,
      error: ''
    }
  },
  methods: {
    checkToken () {
      const urlParams = new URLSearchParams(window.location.search)

      var credentials = {
        token: urlParams.get('token')
      }

      this.error = ''

      if (typeof credentials.token !== 'undefined' && credentials.token !== null && credentials.token.length !== 0) {
        Vue.$logger.info('AuthorizationSmsCode | auth.login | credentials: ', credentials)

        this.sending = true

        auth.login(this.$store, credentials)
          .then((response) => {
            this.sending = false

            const data = response.data
            let role

            try {
              const payload = JSON.parse(data.token.split('.').map(part => {
                return utils.decodeBase64(part)
              })[1])

              role = payload.role
            } catch (error) {
              role = 'user'
            }

            this.$store.commit('user/authenticate')

            this.$store.dispatch('user/login', { data, role }).then((response) => {
              Vue.$logger.debug('AuthorizationSmsCode | authorized')

              localStorage.setItem('login_token', credentials.token)

              this.$router.push('/cabinet').catch(()=>{})
            })
          }).catch((error) => {
            this.sending = false

            this.$emit('hide-errors')
            this.error = appError.getMessage(error.message)

            Vue.$logger.debug('AuthorizationSmsCode | auth.login | ошибка: ', error)
          })
      } else {
        this.$emit('hide-errors')
        this.error = appError.getMessage('no_token')
        Vue.$logger.debug('AuthorizationSmsCode | auth.login | ошибка: ', 'no_token')
      }
    },
    hideErrors () {
      this.error = ''
    },
    afterMounted () {
      this.checkToken()
    }
  }
}
</script>

<style lang="css" scoped>
  .form {
    margin-bottom: 20px;
  }

  .step__fields .form-group {
    margin-bottom: 10px;
  }

  .form-field {
    width: 100%;
  }

  @media (min-width: 480px) {
    .step__fields {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
    }

    .step__fields .form-group {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
</style>
